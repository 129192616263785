import auth0 from "auth0-js";
import Vue from "vue";
import api from "./api";
import StorageWrapper from "@/helpers/storage";
import * as Sentry from "@sentry/browser";
let auth = new Vue({
	blocked: false,
	computed: {
		token: {
			get: function () {
				return StorageWrapper.getItem("id_token");
			},
			set: function (id_token) {
				StorageWrapper.setItem("id_token", id_token);
			},
		},
		accessToken: {
			get: function () {
				return StorageWrapper.getItem("access_token");
			},
			set: function (accessToken) {
				api.setToken(accessToken);
				StorageWrapper.setItem("access_token", accessToken);
			},
		},
		user: {
			get: function () {
				try {
					return JSON.parse(StorageWrapper.getItem("user"));
				} catch (e) {
					return null;
				}
			},
			set: function (user) {
				StorageWrapper.setItem("user", JSON.stringify(user));
			},
		},
		session: {
			get: function () {
				try {
					return JSON.parse(StorageWrapper.getItem("session"));
				} catch (e) {
					return null;
				}
			},
			set: function (session) {
				StorageWrapper.setItem("session", JSON.stringify(session));
			},
		},
	},
	methods: {
		init: function () {
			let token = this.accessToken;
			api.setToken(token);
			window.auth = this;

			// 1x a minute, check if we need to refresh the token
			window.setInterval(() => {
				if (this.isExpiringSoon()) {
					console.log("refreshing token");
					this.refresh();
				}
			}, 1000 * 60);
		},
		getWebAuth: function () {
			console.log("building auth");
			console.log(window.env);
			if (this.__webAuth) {
				return this.__webAuth;
			}
			const config = {
				domain: window.env.VITE_AUTH_DOMAIN,
				clientID: window.env.VITE_AUTH_CLIENTID,
				redirectUri:
					window.location.protocol +
					"//" +
					window.location.hostname +
					":" +
					window.location.port +
					"/callback",
				responseType: "token id_token",
				scope: "openid email profile",
				audience: window.env.VITE_AUTH_AUDIENCE,
			};
			console.log(config);
			this.__webAuth = new auth0.WebAuth(config);
			return this.__webAuth;
		},
		login(state, forceLogin = false, errorCode = null, additionalOptions = {}) {
			var options = {};
			if (state.channelId) {
				options.get_register_token = {
					channel_id: state.channelId,
					device_id: "web",
				};
			}
			options.state = JSON.stringify(state);

			const params = new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			});

			options.signup = params.signup;
			options.signup_email = params.signup_email;

			if (forceLogin === true) {
				// this exists to support passing error codes back to the login screen so we can display them
				options.prompt = "login";
				options.errorCode = errorCode;
			}

			for (let prop in additionalOptions) {
				if (additionalOptions.hasOwnProperty(prop)) {
					options[prop] = additionalOptions[prop];
				}
			}

			this.getWebAuth().authorize(options);
		},
		logout(config) {
			return new Promise(() => {
				StorageWrapper.removeItem("access_token");
				StorageWrapper.removeItem("identity_service_token");
				api.setToken(null);
				StorageWrapper.removeItem("id_token");
				StorageWrapper.removeItem("expires_at_date");
				StorageWrapper.removeItem("user");
				StorageWrapper.removeItem("room");
				return this.getWebAuth().logout(config);
			});
		},
		isAuthenticated() {
			//console.log('Checking authentication: ', StorageWrapper.getItem('expires_at_date'), new Date().getTime())
			return new Date().getTime() < 1 * StorageWrapper.getItem("expires_at_date");
		},
		isExpiringSoon() {
			// within 5m of expiration
			return new Date().getTime() > 1 * StorageWrapper.getItem("expires_at_date") - 60000 * 5;
		},
		isBlocked() {
			return this.blocked;
		},
		refresh() {
			this.getWebAuth().checkSession({}, (err, authResult) => {
				if (err) {
					Sentry.captureException(err, {
						extra: {
							file: "auth.js",
							function: "refresh",
						},
					});
				} else {
					console.log("processing auth result");
					this._processAuthResult(
						authResult,
						function () {
							console.log("refreshed");
						},
						function () {
							console.error("refresh failed");
						},
						err
					);
				}
			});
		},
		_processAuthResult(authResult, resolve, reject, err) {
			console.log("auth result: ", authResult);
			if (authResult && authResult.accessToken && authResult.idToken) {
				// eslint-disable-next-line

				// this stupid thing...if I use a wrapper object like the other properties,
				// it returns null. using local storage works fine. super annoying.
				// it DOES work later at some point, so re-authenticating works just due to the timing
				// inside vue's event loop?
				// it's necessary because the isAuthenticated check requires this value, so
				// if we don't have it set immediately, when we check isAuthenticated in the router,
				// the check fails and re-triggers auth
				const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
				StorageWrapper.setItem("expires_at_date", expiresAt);
				this.accessToken = authResult.accessToken;
				this.token = authResult.idToken;
				this.user = authResult.idTokenPayload;
				let state = null;
				try {
					state = authResult.state ? JSON.parse(authResult.state) : null;
				} catch (e) {
					console.warn("Could not parse state: ", authResult.state);
				}
				
				resolve(state);
			} else if (err) {
				console.log("rejecting");
				Sentry.captureException(err, {
					extra: {
						file: "auth.js",
						function: "_processAuthResult",
					},
				});
				reject(err);

				if (err.error === "access_denied" && err.errorDescription === "use_sso") {
					const state = {
						path: "/",
					};
					this.login(state, true, err.errorDescription);
				} else if (err.error === "unauthorized" && err.errorDescription === "user is blocked") {
					this.blocked = true;
				} else {
					this.logout();
				}
			} else {
				console.log("rejecting no err");
				reject("");
			}
		},
		handleAuthentication() {
			console.log("handling");
			return new Promise((resolve, reject) => {
				this.getWebAuth().parseHash(async (err, authResult) => {
					this._processAuthResult(authResult, resolve, reject, err);
				});
			});
		},
	},
});

export default {
	install: function (Vue) {
		Vue.prototype.$auth = auth;
		auth.init();
	},
	getInstance: function () {
		auth.init();
		return auth;
	},
};
export { auth }